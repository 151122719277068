import { Box } from "@chakra-ui/react"
import React from "react"
import { CreateCompany } from "../components/forms/create_company/create_company"
import Seo from "../components/seo"
import { useDelayedAnalyticsEventOnLand } from "../hooks/analytics"
import { AnalyticsEvents } from "../services/types"

export const CreateCompanyPage = () => {
  useDelayedAnalyticsEventOnLand(AnalyticsEvents.company_create_landed)
  return (
    <Box>
      <Seo title="Create Company" />
      <CreateCompany />
    </Box>
  )
}

export default CreateCompanyPage
