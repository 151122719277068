import { Box, Input, Select } from "@chakra-ui/react"
import React, { useContext, useState } from "react"
import { VomuxButton } from "../../shared/vomux_button"
import { VomuxTextTitle } from "../../shared/vomux_text"
import SignUpBanner from "../../../images/sign_in_comapnies_banner.svg"
import { COLORS_PRIMARY } from "../../shared/vomux_colors"
import { navigate } from "gatsby"
import { ApiContext, CompanyContext } from "../../Root"
import { AnalyticsEvents, CompanySizes } from "../../../services/types"
import { sendAmplitudeEvent } from "../../../services/amplitude"

export const CompanySizeSelect = props => {
  return (
    <Select mt="1rem" borderRadius="10px" width="100%" {...props}>
      <option value={CompanySizes.oneToTen}>1 - 9</option>
      <option value={CompanySizes.elevenToFifty}>10 - 49</option>
      <option value={CompanySizes.fiftyPlus}>50+</option>
    </Select>
  )
}

export const CreateCompanyForm = () => {
  console.log("create company ran")
  const api = useContext(ApiContext)
  const company = useContext(CompanyContext)
  const [company_name, setName] = useState("")
  const [company_size, setSize] = useState(CompanySizes.oneToTen.toString())
  const [company_website, setWebsite] = useState("")

  const handleCreate = async () => {
    if (!company_name) {
      alert("Company name is required.")
      return
    }
    sendAmplitudeEvent(
      AnalyticsEvents.company_create_form_create_company_attempted,
      {
        company_name,
      }
    )
    const createdCompany = await api.client.createCompany({
      company_name,
      company_size,
      company_website,
    })
    console.log("created company", createdCompany)
    if (!createdCompany?.created_id) {
      alert(`${company_name} is already being used.`)
      sendAmplitudeEvent(
        AnalyticsEvents.company_create_form_create_company_failed,
        {
          company_name,
        }
      )
      return
    }
    const companyData = await api.client.getCompanyById(
      createdCompany.created_id
    )
    if (!companyData.company_id) {
      alert(
        `Issue creating company ${company_name} with ID ${createdCompany.created_id}`
      )
      return
    }
    sendAmplitudeEvent(
      AnalyticsEvents.company_create_form_create_company_succeeded,
      {
        company_name,
      }
    )
    console.log("setting company", companyData)
    company.setCompany(companyData)
    const createdInterview = await api.client.createInterview({
      company_id: companyData.company_id,
      language: "javascript",
    })
    if (!createdInterview) {
      sendAmplitudeEvent(
        AnalyticsEvents.company_create_form_create_interview_failed,
        {
          company_id: companyData.company_id,
          company_name,
          language: "javascript",
        }
      )
      navigate("/home")
      return
    }
    const path = `/interview/${createdInterview.created_id}`
    sendAmplitudeEvent(AnalyticsEvents.company_create_form_redirected, {
      path,
    })
    navigate(path)
  }

  return (
    <Box
      zIndex="2"
      height={{ base: "60%", md: "70%" }}
      width={{ base: "80%", md: "60%", lg: "40%" }}
      borderRadius="10px"
      backgroundColor="white"
      boxShadow="0px 10px 8px #bbb"
      display="flex"
      justifyContent="center"
      marginTop={{ base: "6rem", md: "0" }}
    >
      <Box
        width="80%"
        alignItems="center"
        justifyContent="space-evenly"
        display="flex"
        flexDirection="column"
        marginTop="3rem"
      >
        <VomuxTextTitle marginBottom="2rem">Create Company</VomuxTextTitle>
        <Input
          mt="1rem"
          padding=".5rem"
          placeholder="Company Name"
          borderRadius="10px"
          width="100%"
          value={company_name}
          onChange={e => setName(e.target.value)}
        ></Input>
        <CompanySizeSelect onChange={e => setSize(e.target.value)} />
        <Input
          mt="1rem"
          padding=".5rem"
          placeholder="Company Website"
          borderRadius="10px"
          width="100%"
          value={company_website}
          onChange={e => setWebsite(e.target.value)}
        ></Input>
        <VomuxButton
          mt="2rem"
          borderColor={COLORS_PRIMARY.LIGHT_BLUE}
          padding=".5rem"
          backgroundColor={COLORS_PRIMARY.LIGHT_BLUE}
          borderRadius="10px"
          marginBottom="1rem"
          w="100%"
          onClick={handleCreate}
        >
          Create Company
        </VomuxButton>
      </Box>
    </Box>
  )
}
